<script setup>
import { ref, inject } from 'vue';

const $require = inject('$require');
let pageData = ref(null);
let pageNum = ref(1);
let pageSize = ref(10);
let total = ref(0);
let loading = ref(false);
let finished = ref(false);
/***
 *
 * 页面数据
 */
let getList = () => {
  $require.HttpPost('/h5/payment/getDataPayMessage', {
    page_num: pageNum.value,
    page_size: pageSize.value,

  })
    .then((res) => {
      pageData.value = res.data.list;
      total.value = res.data.total;
    });
};
getList();
/**
 * 上拉加载
 */
let onLoad = () => {
  loading.value = false;
  // console.log(arr.length)
  if (pageNum.value >= Math.ceil(total.value / pageSize.value)) {
    finished.value = true;
  } else {
    pageNum.value++;
    getList();
  }
};
</script>
<template>
  <div class="dailyFeeDetail">
    <van-list
      v-model:loading="loading"
      :finished="finished"
      :offset="30"
      finished-text="没有更多了"
      @load="onLoad"
    >
      <div class="pubBox" v-for="(item,index) in pageData" :key="index">
        <div class="list fontBold font32 listAfter">商铺{{index + 1}}：{{item.merchants_stall_name}}</div>
        <div class="pubTable">
          <div class="tableTitle">
            <div>类别</div>
            <div>使用量</div>
            <div>单价</div>
            <div>费用</div>
            <div>固定费用</div>
            <div>总费用</div>
          </div>
          <div class="tableList" v-for="(ite,ind) in item.message" :key="ind">
            <div>{{ite.type_name}}</div>
            <div>{{ite.now_key_number_sum}}({{ite.company}})</div>
            <div>￥{{ite.unit_price}}</div>
            <div>￥{{ite.key_pay_price_sum}}</div>
            <div>￥{{ite.fixed_price_sum}}</div>
            <div>￥{{ite.key_total_price_sum}} </div>
          </div>
        </div>
      </div>
    </van-list>
  </div>
</template>

<style scoped lang="scss">
.dailyFeeDetail {
  padding: 25px;
  box-sizing: border-box;
  min-height: 100vh;
  background-color: #F9F9F9;

}
</style>
